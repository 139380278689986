define('amazium-site/app', ['exports', 'ember', 'amazium-site/resolver', 'ember-load-initializers', 'amazium-site/config/environment'], function (exports, _ember, _amaziumSiteResolver, _emberLoadInitializers, _amaziumSiteConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _amaziumSiteConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _amaziumSiteConfigEnvironment['default'].podModulePrefix,
    Resolver: _amaziumSiteResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _amaziumSiteConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});