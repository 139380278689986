define('amazium-site/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        mobileNavClass: '',

        actions: {
            toggleMobileNav: function toggleMobileNav() {
                if (this.get('mobileNavClass') === '') {
                    this.set('mobileNavClass', 'menu_open');
                } else {
                    this.set('mobileNavClass', '');
                }
            }
        }
    });
});