define("amazium-site/templates/application", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "amazium-site/templates/application.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el1 = dom.createElement("svg");
          dom.setAttribute(el1, "aria-hidden", "true");
          dom.setAttribute(el1, "class", "icon icon--type-amazium icon--onLeft");
          dom.setAttribute(el1, "width", "64");
          dom.setAttribute(el1, "height", "64");
          dom.setAttribute(el1, "viewBox", "0 0 64 64");
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M64 64H0V0h64V64zM2.7 61.3h58.7V2.7H2.7V61.3z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M8 46.8l12-30.2h4.1l12 30.2h-4.5l-3.2-8.5H15.8l-3.2 8.5H8zM17.3 34.4h9.6l-4.8-12.5L17.3 34.4z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M39.1 46.8v-3.8L50.6 28H39.4v-3.8h16.5v3.8L44.5 42.8h11.5v3.9H39.1z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M48.1 8.1V7.7h2.3v0.4L49 11h-0.5l1.4-2.9H48.1z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M50.5 10c0-0.2 0-0.3 0.1-0.5s0.2-0.2 0.4-0.3c-0.3-0.2-0.4-0.4-0.4-0.7 0-0.2 0.1-0.5 0.3-0.6s0.4-0.3 0.8-0.3 0.6 0.1 0.8 0.3 0.3 0.4 0.3 0.6c0 0.3-0.1 0.5-0.4 0.7 0.3 0.2 0.5 0.4 0.5 0.8 0 0.3-0.1 0.5-0.3 0.7S52 11 51.6 11s-0.7-0.1-0.9-0.3C50.6 10.5 50.5 10.3 50.5 10zM50.9 10c0 0.1 0 0.1 0 0.2s0 0.1 0.1 0.2 0.1 0.1 0.2 0.2 0.2 0.1 0.4 0.1 0.3 0 0.4-0.1 0.2-0.1 0.2-0.2c0-0.1 0.1-0.1 0.1-0.2s0-0.1 0-0.2c0-0.4-0.2-0.6-0.7-0.6S50.9 9.6 50.9 10zM51 8.5c0 0.1 0 0.1 0 0.1 0 0 0 0.1 0.1 0.2 0 0.1 0.1 0.1 0.2 0.1S51.5 9 51.6 9s0.2 0 0.3-0.1 0.1-0.1 0.2-0.1 0.1-0.1 0.1-0.2c0 0 0-0.1 0-0.1C52.2 8.2 52 8 51.6 8 51.2 8 51 8.2 51 8.5z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M53.2 11v-0.4h0.5V11H53.2z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M54.1 9.2l0.2-1.5h1.8v0.4h-1.4l-0.1 0.8c0.2 0 0.3-0.1 0.5-0.1 0 0 0 0 0 0 0.4 0 0.7 0.1 0.9 0.3s0.3 0.4 0.3 0.8 -0.1 0.6-0.3 0.8c-0.2 0.2-0.5 0.3-0.9 0.3s-0.7-0.1-1-0.2v-0.5c0.2 0.2 0.5 0.3 0.9 0.3 0.3 0 0.5-0.1 0.6-0.2s0.2-0.3 0.2-0.5c0-0.2-0.1-0.4-0.2-0.5S55.3 9.3 55 9.3c-0.2 0-0.5 0-0.7 0.1L54.1 9.2z");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.5.1",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 11,
              "column": 4
            }
          },
          "moduleName": "amazium-site/templates/application.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el1 = dom.createElement("svg");
          dom.setAttribute(el1, "aria-hidden", "true");
          dom.setAttribute(el1, "class", "icon icon--type-amazium icon--onLeft");
          dom.setAttribute(el1, "width", "64");
          dom.setAttribute(el1, "height", "64");
          dom.setAttribute(el1, "viewBox", "0 0 64 64");
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M64 64H0V0h64V64zM2.7 61.3h58.7V2.7H2.7V61.3z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M8 46.8l12-30.2h4.1l12 30.2h-4.5l-3.2-8.5H15.8l-3.2 8.5H8zM17.3 34.4h9.6l-4.8-12.5L17.3 34.4z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M39.1 46.8v-3.8L50.6 28H39.4v-3.8h16.5v3.8L44.5 42.8h11.5v3.9H39.1z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M48.1 8.1V7.7h2.3v0.4L49 11h-0.5l1.4-2.9H48.1z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M50.5 10c0-0.2 0-0.3 0.1-0.5s0.2-0.2 0.4-0.3c-0.3-0.2-0.4-0.4-0.4-0.7 0-0.2 0.1-0.5 0.3-0.6s0.4-0.3 0.8-0.3 0.6 0.1 0.8 0.3 0.3 0.4 0.3 0.6c0 0.3-0.1 0.5-0.4 0.7 0.3 0.2 0.5 0.4 0.5 0.8 0 0.3-0.1 0.5-0.3 0.7S52 11 51.6 11s-0.7-0.1-0.9-0.3C50.6 10.5 50.5 10.3 50.5 10zM50.9 10c0 0.1 0 0.1 0 0.2s0 0.1 0.1 0.2 0.1 0.1 0.2 0.2 0.2 0.1 0.4 0.1 0.3 0 0.4-0.1 0.2-0.1 0.2-0.2c0-0.1 0.1-0.1 0.1-0.2s0-0.1 0-0.2c0-0.4-0.2-0.6-0.7-0.6S50.9 9.6 50.9 10zM51 8.5c0 0.1 0 0.1 0 0.1 0 0 0 0.1 0.1 0.2 0 0.1 0.1 0.1 0.2 0.1S51.5 9 51.6 9s0.2 0 0.3-0.1 0.1-0.1 0.2-0.1 0.1-0.1 0.1-0.2c0 0 0-0.1 0-0.1C52.2 8.2 52 8 51.6 8 51.2 8 51 8.2 51 8.5z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M53.2 11v-0.4h0.5V11H53.2z");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("path");
          dom.setAttribute(el2, "d", "M54.1 9.2l0.2-1.5h1.8v0.4h-1.4l-0.1 0.8c0.2 0 0.3-0.1 0.5-0.1 0 0 0 0 0 0 0.4 0 0.7 0.1 0.9 0.3s0.3 0.4 0.3 0.8 -0.1 0.6-0.3 0.8c-0.2 0.2-0.5 0.3-0.9 0.3s-0.7-0.1-1-0.2v-0.5c0.2 0.2 0.5 0.3 0.9 0.3 0.3 0 0.5-0.1 0.6-0.2s0.2-0.3 0.2-0.5c0-0.2-0.1-0.4-0.2-0.5S55.3 9.3 55 9.3c-0.2 0-0.5 0-0.7 0.1L54.1 9.2z");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.5.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 0
          }
        },
        "moduleName": "amazium-site/templates/application.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1, "id", "top");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "mobile_nav");
        var el3 = dom.createElement("span");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "navigation-wrapper");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ol");
        dom.setAttribute(el3, "class", "nav-left");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ol");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ol");
        dom.setAttribute(el3, "class", "nav-right");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("li");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "https://github.com/OwlyStuff/Amazium");
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el6 = dom.createElement("svg");
        dom.setAttribute(el6, "aria-hidden", "true");
        dom.setAttribute(el6, "class", "icon icon--type-github icon--onLeft");
        dom.setAttribute(el6, "width", "64");
        dom.setAttribute(el6, "height", "64");
        dom.setAttribute(el6, "viewBox", "0 0 64 64");
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "d", "M32 0L32 0C14.3 0 0 15.3 0 33.4c0 14.4 9.2 26.3 21.7 30.6 1.6 0.3 2.3-0.7 2.3-1.6 0-0.7 0-3 0-5.6 -9 2-10.9-4.3-10.9-4.3 -1.3-3.6-3.5-4.9-3.5-4.9 -2.9-2 0.3-2 0.3-2 3.2 0.3 4.8 3.3 4.8 3.3 2.9 5.3 7.7 3.9 9.3 3 0.3-2 1-3.6 1.9-4.3 -6.7-1-14.5-3.6-14.5-16.4 0-3.6 1.3-6.6 3.2-8.9 -0.3-0.7-1.3-3.9 0.3-8.5 0 0 2.6-1 8.7 3.3 2.9-0.7 5.8-1 8.4-1s5.5 0.3 8 1c6.1-4.3 8.7-3.3 8.7-3.3 1.6 4.6 0.6 7.9 0.3 8.5 1.9 2.3 3.2 5.3 3.2 8.9 0 12.5-7.3 15.4-14.6 16.1 1.3 1 2.4 3 2.4 6.2 0 4.3 0 7.9 0 8.9 0 1 0.5 2 2.1 1.6C55 59.7 64 47.8 64 33.4 64.3 15.3 49.6 0 32 0z");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Github");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.setNamespace(null);
        var el4 = dom.createElement("li");
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "https://github.com/OwlyStuff/Amazium/releases/latest");
        dom.setAttribute(el5, "target", "_blank");
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el6 = dom.createElement("svg");
        dom.setAttribute(el6, "aria-hidden", "true");
        dom.setAttribute(el6, "class", "icon icon--type-arrowDownload icon--onLeft");
        dom.setAttribute(el6, "width", "64");
        dom.setAttribute(el6, "height", "64");
        dom.setAttribute(el6, "viewBox", "0 0 64 64");
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "class", "st0");
        dom.setAttribute(el7, "d", "M56.9 12.3c-0.6 0.1-1.2 0.4-1.7 0.8 -3 3-17.7 17.7-21.3 21.3 -0.6 0.6-1.4 1-2.3 0.9 -0.9-0.1-1.2-0.6-2-1.4L8.9 13.2c-0.5-0.5-1-0.8-1.7-0.8C4.4 12 3 15.3 4.8 17.1l24.3 24.3c1.6 1.6 4.1 1.6 5.7 0l24.4-24.4C61 15.2 59.6 12 56.9 12.3z");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("path");
        dom.setAttribute(el7, "class", "st0");
        dom.setAttribute(el7, "d", "M57.1 53.2H7c-1.6 0-2.9-1.3-2.9-2.9v0c0-1.6 1.3-2.9 2.9-2.9h50.1c1.6 0 2.9 1.3 2.9 2.9v0C60 51.9 58.7 53.2 57.1 53.2z");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Download");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("footer");
        dom.setAttribute(el1, "class", "footer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "float--left");
        var el3 = dom.createTextNode("This site may contain traces of Amazium");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "float--right");
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "https://github.com/OwlyStuff/Amazium");
        dom.setAttribute(el3, "target", "_blank");
        var el4 = dom.createTextNode("View Amazium on Github");
        dom.appendChild(el3, el4);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el4 = dom.createElement("svg");
        dom.setAttribute(el4, "aria-hidden", "true");
        dom.setAttribute(el4, "class", "icon icon--type-github icon--onRight");
        dom.setAttribute(el4, "width", "64");
        dom.setAttribute(el4, "height", "64");
        dom.setAttribute(el4, "viewBox", "0 0 64 64");
        var el5 = dom.createElement("path");
        dom.setAttribute(el5, "d", "M32 0L32 0C14.3 0 0 15.3 0 33.4c0 14.4 9.2 26.3 21.7 30.6 1.6 0.3 2.3-0.7 2.3-1.6 0-0.7 0-3 0-5.6 -9 2-10.9-4.3-10.9-4.3 -1.3-3.6-3.5-4.9-3.5-4.9 -2.9-2 0.3-2 0.3-2 3.2 0.3 4.8 3.3 4.8 3.3 2.9 5.3 7.7 3.9 9.3 3 0.3-2 1-3.6 1.9-4.3 -6.7-1-14.5-3.6-14.5-16.4 0-3.6 1.3-6.6 3.2-8.9 -0.3-0.7-1.3-3.9 0.3-8.5 0 0 2.6-1 8.7 3.3 2.9-0.7 5.8-1 8.4-1s5.5 0.3 8 1c6.1-4.3 8.7-3.3 8.7-3.3 1.6 4.6 0.6 7.9 0.3 8.5 1.9 2.3 3.2 5.3 3.2 8.9 0 12.5-7.3 15.4-14.6 16.1 1.3 1 2.4 3 2.4 6.2 0 4.3 0 7.9 0 8.9 0 1 0.5 2 2.1 1.6C55 59.7 64 47.8 64 33.4 64.3 15.3 49.6 0 32 0z");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element3, [3]);
        var element5 = dom.childAt(element4, [2]);
        var element6 = dom.childAt(element3, [5]);
        var element7 = dom.childAt(element6, [2]);
        var element8 = dom.childAt(element3, [7]);
        var element9 = dom.childAt(element8, [2]);
        var morphs = new Array(16);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createMorphAt(element2, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(element4, 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element5, [3]), 0, 0);
        morphs[8] = dom.createMorphAt(element6, 0, 0);
        morphs[9] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
        morphs[10] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
        morphs[11] = dom.createMorphAt(dom.childAt(element7, [5]), 0, 0);
        morphs[12] = dom.createMorphAt(element8, 0, 0);
        morphs[13] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
        morphs[14] = dom.createMorphAt(dom.childAt(element9, [3]), 0, 0);
        morphs[15] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["navigation ", ["get", "mobileNavClass", ["loc", [null, [1, 34], [1, 48]]]]]]], ["block", "link-to", ["index"], ["class", "logo"], 0, null, ["loc", [null, [2, 2], [4, 14]]]], ["element", "action", ["toggleMobileNav"], [], ["loc", [null, [6, 29], [6, 57]]]], ["block", "link-to", ["index"], ["class", "logo-mobile"], 1, null, ["loc", [null, [9, 4], [11, 16]]]], ["inline", "link-to", ["Homepage", "index"], [], ["loc", [null, [13, 10], [13, 40]]]], ["inline", "link-to", ["Structure", "structure"], [], ["loc", [null, [14, 10], [14, 45]]]], ["inline", "link-to", ["Grid", "structure.grid"], [], ["loc", [null, [16, 14], [16, 49]]]], ["inline", "link-to", ["Columns", "structure.columns"], [], ["loc", [null, [17, 14], [17, 55]]]], ["inline", "link-to", ["Style Kit", "stylekit"], [], ["loc", [null, [20, 10], [20, 44]]]], ["inline", "link-to", ["Typography", "stylekit.typography"], [], ["loc", [null, [22, 14], [22, 60]]]], ["inline", "link-to", ["Colors", "stylekit.colors"], [], ["loc", [null, [23, 14], [23, 52]]]], ["inline", "link-to", ["Components", "stylekit.components"], [], ["loc", [null, [24, 14], [24, 60]]]], ["inline", "link-to", ["Forms", "form"], [], ["loc", [null, [27, 10], [27, 36]]]], ["inline", "link-to", ["Inputs", "form.inputs"], [], ["loc", [null, [29, 14], [29, 48]]]], ["inline", "link-to", ["Buttons", "form.buttons"], [], ["loc", [null, [30, 14], [30, 50]]]], ["content", "outlet", ["loc", [null, [41, 0], [41, 10]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});