define('amazium-site/router', ['exports', 'ember', 'amazium-site/config/environment'], function (exports, _ember, _amaziumSiteConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _amaziumSiteConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('structure', function () {
      this.route('grid');
      this.route('columns');
    });
    this.route('stylekit', function () {
      this.route('components');
      this.route('colors');
      this.route('typography');
    });
    this.route('form', function () {
      this.route('buttons');
      this.route('inputs');
    });
  });

  exports['default'] = Router;
});